import { Injectable } from '@angular/core';
import { BehaviorSubject, Observable } from 'rxjs';
import { ApiService } from './api.service';
import { AdminRolesEnum } from '../roles/role.model';
import { Organization } from '../../features/organizations/_common/models/organizations.interface';
import { ResponsiblePerson } from '../../features/organizations/_common/models/responsible-person.model';

export class AdminInfo {
  id: string;
  cognito_username: string;
  email: string;
  tickets: number;
  organizationId: string;
  organization_id: string;
  singler: string;
  role: AdminRolesEnum;
  status: number;
  checks_monthly_limit: number;
  checks_monthly_limit_available?: number;
  checks_monthly_limit_used?: number;
  signature_path: string;
  signature: string;
  subscribed: boolean;
  invoice_company_name: string;
  first_name: string;
  last_name: string;
  address: string;
  postal_code: string;
  city: string;
  phone: string;
  logo: string;
  createdAt: string;
  updatedAt: string;

  organizations: Organization[] | ResponsiblePerson[];
}

@Injectable({
  providedIn: 'root',
})
export class GlobalDataService {
  constructor(private api: ApiService) {}

  //#region _loader
  private _loader: BehaviorSubject<boolean> = new BehaviorSubject(null);

  public subscribeLoader(): Observable<boolean> {
    return this._loader.asObservable();
  }

  public emitLoader(status: boolean): void {
    this._loader.next(status);
  }
  //#endregion

  //#region _activeOrganization
  private _activeOrganization: BehaviorSubject<string> = new BehaviorSubject(
    null,
  );

  public subscribeOrganization(): Observable<string> {
    return this._activeOrganization.asObservable();
  }

  public emitOrganization(organization: string): void {
    this._activeOrganization.next(organization);
  }
  //#endregion

  //#region _activeTabAddEq
  private _activeTabAddEq: BehaviorSubject<number> = new BehaviorSubject(null);

  public subscribeActiveTabAddEq(): Observable<number> {
    return this._activeTabAddEq.asObservable();
  }

  public emitActiveTabAddEq(data: number): void {
    this._activeTabAddEq.next(data);
  }
  //#endregion

  //#region _previewData
  private _previewData: BehaviorSubject<any> = new BehaviorSubject(null);

  public subscribePreviewData(): Observable<any> {
    return this._previewData.asObservable();
  }

  public emitPreviewData(data: any): void {
    this._previewData.next(data);
  }
  //#endregion

  //#region _activeSidebarTab
  private _activeSidebarTab: BehaviorSubject<number> = new BehaviorSubject(
    null,
  );

  public subscribeActiveSidebarTab(): Observable<number> {
    return this._activeSidebarTab.asObservable();
  }

  public emitActiveSidebarTab(data: number): void {
    this._activeSidebarTab.next(data);
  }
  //#endregion

  //#region _activePreviewTab
  private _activePreviewTab: BehaviorSubject<any> = new BehaviorSubject(null);

  public subscribeActivePreviewTab(): Observable<any> {
    return this._activePreviewTab.asObservable();
  }

  public emitActivePreviewTab(data: any): void {
    this._activePreviewTab.next(data);
  }
  //#endregion

  //#region _userData
  private _userData: BehaviorSubject<AdminInfo> = new BehaviorSubject(null);

  public subscribeUserData(): Observable<AdminInfo> {
    return this._userData.asObservable();
  }

  public emitUserData(data: AdminInfo): void {
    this._userData.next(data);
  }

  public getUserValue(): AdminInfo {
    return this._userData.getValue();
  }
  //#endregion

  public getAuth(): Observable<AdminInfo> {
    return this.api.get('admin/info');
  }
}
